import React from "react";
import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby";
import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import * as CONST from "../../../../components/const";
const kv = "../../../../images/business/security_service/ctf/kv.png";
const content1 = "../../../../images/business/security_service/ctf/img1.png";
const content2 = "../../../../images/business/security_service/ctf/img2.png";

function EltexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		<Layout addClassName="bg-gray-light">
			<SEO
				description="ALPHA WAVEのセキュリティーサービスの実績です。株式会社エルテックス様の例をご覧ください。"
				title="株式会社エルテックス様"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
					</div>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full"} src={kv} />
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<p className="">株式会社エルテックス様よりWEBをテーマにしたCTF開催のご依頼をいただきました。</p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={kv} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " 3xl:-mb-24"}>
				<h1 className={CONST.H1_CLASS + " font-light"}><span className="inline-block">株式会社</span><span className="inline-block">エルテックス様</span></h1>
			</section>

			<section className={CONST.SECTION_AREA_REVERSE_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS + " 3xl:-mt-24"}>
					<div>
						<h6 className={CONST.H6_CLASS + CONST.PC_SHOW_CLASS}>弊社のCTFをご利用されようと思ったきっかけを教えてください</h6>
						<p>神情協の技術セミナーにて貴社がCTFを開催された際に自社から2チーム参加させていただきました。</p>
						<p>CTF参加者が楽しそうに集中して取り組んでいる様子を見て、技術セミナーとして社内でもこういった取り組みができないかと感じていました。</p><br />
						<p>ただ、外部のセミナーですと仕事の都合で参加できないメンバーも多いことから、最初は自社開催を検討し色々と調べて見ましたが、問題作成のハードルがかなり高く、開催の難しさに直面していました。</p>
						<p>神情協の総会の時にアルファ・ウェーブさんとお話する機会があり、神情協で開催したCTFが非常に素晴らしかったので、弊社向けに開催できないか相談させていただいたところ、個別にも実施できるとのお話をいただき、それでは是非との事で開催するに至りました。</p><br />
						<p>開催に当たっては、普段セキュアコーディングなどの知識は多少あるものの、実際にハッカーがどんな事をするのか知ってほしいとの思いで、入社三年目位までの社員は強制的に受講させる事にしました。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<h6 className={CONST.H6_CLASS + CONST.PC_HIDDEN_CLASS}>弊社のCTFをご利用されようと思ったきっかけを教えてください</h6>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={content1} />
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<h6 className={CONST.H6_CLASS + CONST.PC_HIDDEN_CLASS}>問題の方向性や内容などはご要望と合っていましたか</h6>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={content2} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<h6 className={CONST.H6_CLASS + CONST.PC_SHOW_CLASS}>問題の方向性や内容などはご要望と合っていましたか</h6>
						<p>WEBセキュリティという切り口での問題は、弊社での業務に非常にマッチしていました。また、WEB以外の問題でも、ハッカーからの攻撃対象等を知る事が出来、非常に有効な内容と思いました。</p>
						<p>問題の難易度についても、難しすぎると皆ついてこれず、簡単すぎるとセキュリティを甘く見てしまう事になりかねないので、時間内一生懸命やって全て解けた解けないという、絶妙な難易度だったと感じています。</p>
						<p>もっと時間が欲しかったという意見もありましたが、解けない所は後々自主的に調べるなどの意識付けに繋がったと思います。</p>
						<p>また、開発上気を付けなければいけない観点も充分伝わったと感じています。</p>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-full lg:w-9/12">
					<h6 className={CONST.H6_CLASS}>開催する前と後の社内の反応はいかがでしたか</h6>
					<p>正直開催する前は社内にアナウンスをしても何も反応がなく、開催者としては不安な状態でしたが、</p>
					<p>開催後に実施したアンケートでは非常に高評価で、開催して良かったと感じています。</p><br />
					<p>このようなセミナーは参加しないと重要性・楽しさなど伝わらないため、次回開催の時には、今回の参加者から広めていけたら良いなと感じています。</p>
				</div>
				<div className="w-full lg:w-3/12"></div>
			</section>

			{/* <section className={CONST.SECTION_P_AREA_CLASS + " justify-center"}>
				<Link to="../../">実績トップ</Link>
			</section> */}

		</Layout>
	);
}

export default EltexPage;
